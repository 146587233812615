<template>
	<div class="foort">
		<div class="footer-box">
			<div class="zuo">
				<div class="name">2023-2024赛季国际滑联世界花样滑冰大奖赛总决赛组委会</div>
				<div class="dz">地址：{{data.address}}</div>
			</div>
			<div class="you">
				<div class="link">
					<div class="name">相关链接</div>
					<div v-for="(item,index) in fLinks" v-if="index < 5">
						<div class="dz" @click="toggleSubMenu(item.pathUrl)">{{item.name}}</div>
					</div>
				</div>
				<div class="qr-box">
					<div class="qr">
						<img class="wb-wx1" :src="data.file2" alt="">
						<div class="wbtitle">官方微信服务号</div>
					</div>
					<div class="qrwx">
						<img class="wb-wx1" :src="data.file3" alt="">
						<div class="wbtitle">官方微信订阅号</div>
					</div>
					<div class="qrwx">
						<img class="wb-wx1" :src="data.file4" alt="">
						<div class="wbtitle">官方微博</div>
					</div>
				</div>
			</div>
		</div>
		<div class="digest">版权所有：{{data.from_to}} </div>
		<div class="digest2" v-html="data.digest"></div>
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		data() {
			return {
				data:{},
				fLinks:[]
			}
		},
		created() {
			this.$store.commit("EXIT_STATIONID", this.$route.query.id)
			this.findFooter()
			this.getFriendLink()
		},
		methods: {
			findFooter() {
				this.$api.findFooter().then(res => {
					this.data = res.data.data[0]
				})
			},
			getFriendLink() {
				this.$api.getFriendLink({type:0}).then(res => {
					this.fLinks = res.data.data
				})
			},
			toggleSubMenu(url) {
				window.open(url, '_blank');
			},
		}
	}
</script>

<style scoped lang="scss">
	.foort{
		width: 100%;
		height: 3.82rem;
		background: url('../../../assets/eventtheme/hbback7.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
		.footer-box{
			width: 12.00rem;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			.zuo{
				padding-top: 0.66rem;
				.name{
					font-size: 0.17rem;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
				}
				.dz{
					font-size: 0.15rem;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					margin-top: 0.22rem;
				}
				.iphone{
					font-size: 0.15rem;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					margin-top: 0.22rem;
				}
				.emj{
					font-size: 0.15rem;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					margin-top: 0.22rem;
				}
			}
			.you{
				display: flex;
				.link{
					padding-top: 0.66rem;
					.name{
						font-size: 0.17rem;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #000000;
					}
					.dz{
						font-size: 0.15rem;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #000000;
						margin-top: 0.22rem;
						cursor: pointer;
					}
					.iphone{
						font-size: 0.15rem;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #000000;
						margin-top: 0.22rem;
					}
				}
					
				.qr-box{
					display:flex;
					margin-left: 0.78rem;
					margin-top: 0.61rem;
					
					.wb-wx1 {
						width: 0.85rem;
						height: 0.83rem;
					}
					
					.wbtitle {
						font-size: 0.12rem;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #000000;
						margin-top: 0.14rem;
					}
					
					.qr{
						display: flex;
						flex-flow: column;
						align-items: center;
					}
					.qrwx{
						margin: 0 0 0 0.20rem;
						display: flex;
						flex-flow: column;
						align-items: center;
					}
				}
			}
		}
		.digest{
			font-size: 0.12rem;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #000000;
			width: 12.00rem;
			margin: 0.68rem auto 0;
			text-align: right;
		}
		.digest2{
			font-size: 0.12rem;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #000000;
			width: 12.00rem;
			margin: 0.18rem auto 0;
			text-align: right;
		}
	}
</style>