<template>
	<div class="bady">
		<headerbox :logo="logo"></headerbox>

		<div class="carousel">
			<el-carousel :interval="5000" arrow="always" height="5.47rem" indicator-position="none">
				<el-carousel-item v-for="(item,index) in topImg" :key="index">
					<img :src="item.imgUrl" alt="" class="imgUrl">
				</el-carousel-item>
			</el-carousel>
		</div>

		<div class="box" v-if="!this.$verification(allData[0])">
			<div class="title-box">
				<div class="title" v-for="(item,index) in allData[0]" :key="index" v-if="index<2" @click="getmore(allData[0][0],0)">
					{{item.name}}
				</div>
			</div>
			<div class="content-box" v-if="!this.$verification(allData[0][0])">
				<!-- <div class="content">
					<div class="content-title" v-if="!this.$verification(allData[0][0].articles[0])"
						@click="jump(allData[0][0].articles[0].id)">{{allData[0][0].articles[0].title}}
					</div>
					<div class="content-title-box">
						<div class="content-title-box-title magin" v-if="!this.$verification(allData[0][0].articles[1])"
							@click="jump(allData[0][0].articles[1].id)">
							<span class="sp">比赛动态</span>
							<span class="xf"> {{allData[0][0].articles[1].title}}</span>
						</div>
						<div class="content-title-box-title" v-if="allData[0][0].articles[2]"
							@click="jump(allData[0][0].articles[2].id)">
							<span class="sp">比赛动态</span>
							<span class="xf"> {{allData[0][0].articles[2].title}}</span>
						</div>
					</div>
				</div> -->
				<div class="img-layout">
					<div class="back">
						<div class="layout1" v-if="!this.$verification(allData[0][0].articles[0])"
							@click="jump(allData[0][0].articles[0].id)">
							<img :src="allData[0][0].articles[0].imgUrl" alt="" class="img">
							<div class="title">{{allData[0][0].articles[0].title}}</div>
							<div class="introduction" v-html="allData[0][0].articles[0].content"></div>
							<div class="time">[{{allData[0][0].articles[0].publishTime | format }}]</div>
							<!-- <div class="button" @click="jump(allData[0][0].articles[3].id)">
								查看详情
								<img src="../../assets/eventtheme/more.png" alt="">
							</div> -->
						</div>
					</div>
					<div class="layout2">
						<div class="back">
							<div class="layout22" v-if="!this.$verification(allData[0][0].articles[1])"
								@click="jump(allData[0][0].articles[1].id)">
								<img :src="allData[0][0].articles[1].imgUrl" alt="" class="img">
								<div class="title">{{allData[0][0].articles[1].title}}</div>
								<div class="time time1">[{{allData[0][0].articles[1].publishTime | format }}]</div>
							</div>
						</div>
						<div class="back">
							<div class="layout22" v-if="!this.$verification(allData[0][0].articles[2])"
								@click="jump(allData[0][0].articles[2].id)">
								<img :src="allData[0][0].articles[2].imgUrl" alt="" class="img">
								<div class="title">{{allData[0][0].articles[2].title}}</div>
								<div class="time">[{{allData[0][0].articles[2].publishTime | format }}]</div>
							</div>
						</div>
					</div>
				</div>
				<div class="button" @click="getmore(allData[0][0],0)">
					更多
					<img src="../../assets/eventtheme/more.png" alt="">
				</div>
			</div>
		</div>

		<!-- <div class="box schedule">
			<div class="title-box">
				<div class="title">
					赛事日程
				</div>
			</div>
			<div class="calendar">
				<div>
					<Calendar @getDate="getDate" :infoDate="infoDate" @plusMonth="plusMonth" @minusMonth="minusMonth">
					</Calendar>
				</div>
				<div class="flrex">
					<div class="information" v-for="(item,index) in mchList" :key="index">
						<div class="rubric">
							<div class="rubricbox">
								{{item.name}}
							</div>
						</div>
						<div class="date">
							<div class="sj">时间</div>
							<div v-if="item.isJSON">
								<div v-for="(item,index) in item.start_time" class="jtsj">
									{{getTime(item)}}
								</div>
							</div>
							<div v-if="!item.isJSON" class="jtsj">
								{{item.start_time}} - {{ item.end_time}}
							</div>
						</div>
						<div class="location">
							<div class="dz">地点</div>
							<div class="roundtext">{{item.address}}</div>
						</div>
					</div>
				</div>
			</div>
		</div> -->

		<div class="box giveserviceto">
			<div class="title-box">
				<div class="title">
					赛事文化
				</div>
			</div>
			<div class="content-box">
				<div class="content" @click="getmore2(926,927,'赛事会徽',3)">
					<div class="name">会徽</div>
					<div class="brief">
						赛事会徽以纯洁的“冰”作为灵感来源，以冰的“透叠”特性，将运动员的身姿与地球经纬融为一体，共同构成向前而行的“花滑冰帆”，呈现出新时代下花滑运动的新目标、新梦想，传递出中国推动世界花滑运动发展的追求，并为赛事的精彩呈现做出不懈努力。
					</div>
				</div>
				<div class="content2" @click="getmore2(926,928,'赛事奖牌',3)">
					<div class="name">奖牌</div>
					<div class="brief"></div>
				</div>
			</div>
		</div>

		<div class="box schedule">
			<div class="title-box" style="padding-top: 91px;">
				<div class="title" @click="getmore2(0,911,'票务信息',2)">
					票务信息
				</div>
			</div>
			<div class="ticketing">
				<div class="ticketing-box">
					<div class="title">2023年世界花样滑冰大奖赛总决赛</div>
					<div class="time-box">
						<div class="nian">2023</div>
						<div class="nian1">12/07</div>
						<div class="nian2">SAT</div>
						<div class="nian3"></div>
						<div class="nian4">12/10</div>
						<div class="nian5">sun</div>
					</div>
				</div>
				<div class="rubric">公众售票官方网站</div>
				<div class="button" @click="getmore2(0,911,'票务信息',2)">查看详情</div>
				<div class="bottom">
					<div class="time">2023/12.07</div>
					<div class="title">距离2023年世界花样滑冰大奖赛总决赛</div>
					<div class="djs">倒计时：</div>
					<div class="num">{{gettitme}}</div>
					<div class="tian">天</div>
				</div>
			</div>
		</div>
		<div class="box wrc" v-if="!this.$verification(allData[1]) && !this.$verification(allData[1][0].articles)">
			<div class="title-box" style="padding-top: 91px;">
				<div class="title" @click="getmore(allData[1][0],6)">
					赛事集锦
				</div>
			</div>
			<div style="display: flex;">
				<div class="image-box">
					<div v-for="(item,index) in allData[1][0].articles" @click="jump(item.id)"
						class="img" v-if="index < 6">
						<img :src="item.imgUrl" alt="">
					</div>
				</div>
			</div>
			<div style="padding-bottom: 91px;">
				<div class="button" @click="getmore(allData[1][0],6)">
					更多
					<img src="../../assets/eventtheme/more.png" alt="">
				</div>
			</div>
			<div class="title-box" style="padding-bottom: 60px;" v-if="!this.$verification(allData[2])">
				<div class="title"  @click="getmore(allData[2][0],7)">
					城市信息
				</div>
			</div>
		</div>
		<div class="map" v-if="!this.$verification(allData[2])">
			<div class="pop-up">
				<div>
					<div class="jianj" @click="jump(allData[2][0].articles[0].id)"
						v-if="!this.$verification(allData[2][0].articles[0])">
						<img :src="allData[2][0].articles[0].imgUrl " alt="">
						<div class="title-boxx">
							<div class="name">{{allData[2][0].articles[0].title}}</div>
							<div class="name2">{{getText(allData[2][0].articles[0].content)}}</div>
						</div>
					</div>
					<div class="bottom">
						<div class="xq-box">
							<div class="xq" @click="jump(allData[2][0].articles[1].id)"
								v-if="!this.$verification(allData[2][0].articles[1])">
								<div class="xian"></div>
								<div class="name">{{allData[2][0].articles[1].title}}</div>
							</div>
							<div class="xq xq2" @click="jump(allData[2][0].articles[2].id)"
								v-if="!this.$verification(allData[2][0].articles[2])">
								<div class="xian"></div>
								<div class="name">{{allData[2][0].articles[2].title}}</div>
							</div>
						</div>
					</div>
					<div class="button" @click="getmore(allData[2][0],7)">
						更多
						<img src="../../assets/eventtheme/more.png" alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="box sponsor-box">
			<div class="title-box" style="padding-top: 91px;">
				<div class="title">
					赞助商
				</div>
			</div>
			<div class="sponsor">
				<div class="title">官方赞助商</div>
				<div class="xian"></div>
				<div class="box">
					<div class="pp" v-for="(item,index) in sponsor" :key="index" @click="Jump(item)" v-if="index < 8">
						<img :src="item.imgUrl" alt="">
					</div>
					<!-- <div class="pp"></div>
					<div class="pp"></div>
					<div class="pp"></div>
					<div class="pp"></div>
					<div class="pp"></div>
					<div class="pp"></div>
					<div class="pp"></div> -->
				</div>
			</div>
			<div class="sponsor">
				<div class="title" style="margin-top: 27px;">供应商</div>
				<div class="xian"></div>
				<div class="box">
					<div class="pp" v-for="(item,index) in supplier" :key="index" @click="Jump(item)" v-if="index < 8">
						<img :src="item.imgUrl" alt="">
					</div>
					<!-- <div class="pp"></div>
					<div class="pp"></div>
					<div class="pp"></div>
					<div class="pp" style="margin-left: 31px;"></div>
					<div class="pp"></div> -->
				</div>
			</div>
			<!-- <div class="button-box">
				<div class="button" @click="getmore2(0,0,'赞助商',0)">
					更多
					<img src="../../assets/eventtheme/more.png" alt="">
				</div>
			</div> -->
			<div class="sponsor" style="padding-bottom: 40px;">
				<div class="title" style="margin-top: 27px;">特许经营</div>
				<div class="xian"></div>
				<div class="box">
					<div class="pp2" @click="getmore2(0,0,'特许产品',0)">赛事特许产品</div> <!-- style="margin-right: 62px;" -->
					<!-- <div class="pp2">特许商品防伪查询</div> -->
				</div>
			</div>
		</div>

		<footerbox></footerbox>
	</div>
</template>

<script>
	import headerbox from './component/header.vue'
	import Calendar from '@/components/calendar3.vue'
	import footerbox from './component/footer.vue'
	export default {
		name: '',
		components: {
			Calendar,
			headerbox,
			footerbox
		},
		data() {
			return {
				logo: '',
				navList: [],
				navindex: 0,
				topImg: [],
				mchList: [],
				infoDate: [],
				allData: [],
				sponsor: [],
				supplier: []
			}
		},
		created() {
			this.logo = this.$route.query.logo

			document.title = this.$route.query.title + '-北京市体育竞赛管理和国际交流中心'
			this.$meta().refresh()

			this.$store.commit("EXIT_STATIONID", this.$route.query.id)

			this.getdata()
			this.getTopBanner() // 轮播图
			this.getIndexColumn() // 内容
			this.findList()
		},
		mounted() {
			// rem px 换算
			(function flexible(window, document) {
				var docEl = document.documentElement
				var dpr = window.devicePixelRatio || 1

				function setBodyFontSize() {
					if (document.body) {
						document.body.style.fontSize = (15 * dpr) + 'px'
					} else {
						document.addEventListener('DOMContentLoaded', setBodyFontSize)
					}
				}
				setBodyFontSize();

				function setRemUnit() {
					var rem = (docEl.clientWidth / 2032) * 100
					docEl.style.fontSize = rem + 'px'
				}
				setRemUnit()
				window.addEventListener('resize', setRemUnit)
				window.addEventListener('pageshow', function(e) {
					if (e.persisted) {
						setRemUnit()
					}
				})
				if (dpr >= 2) {
					var fakeBody = document.createElement('body')
					var testElement = document.createElement('div')
					testElement.style.border = '.5px solid transparent'
					fakeBody.appendChild(testElement)
					docEl.appendChild(fakeBody)
					if (testElement.offsetHeight === 1) {
						docEl.classList.add('hairlines')
					}
					docEl.removeChild(fakeBody)
				}
			}(window, document))
		},
		computed: {
			gettitme: function() {
				const targetDate = new Date('2023-12-07');
				// 获取当前日期的时间戳（以毫秒为单位）
				const currentDate = new Date();
				const currentTimestamp = currentDate.getTime();
				// 获取目标日期的时间戳（以毫秒为单位）
				const targetTimestamp = targetDate.getTime();
				// 计算时间戳差值（以毫秒为单位）
				const timeDifference = targetTimestamp - currentTimestamp;
				// 将毫秒转换为天数
				const millisecondsPerDay = 24 * 60 * 60 * 1000; // 一天的毫秒数
				const daysDifference = Math.floor(timeDifference / millisecondsPerDay);
				return daysDifference
			}
		},
		filters: {
			format(x) {
				if (x != null && x != '') {
					return x.split(" ")[0]
				}
			},
		},
		methods: {
			findList() {
				this.$api.getFriendLink({
					type: 1
				}).then(res => {
					this.sponsor = res.data.data
				})
				this.$api.getFriendLink({
					type: 2
				}).then(res => {
					this.supplier = res.data.data
				})
			},
			goDingyue(item, index) {
				this.navindex = index
			},
			getTopBanner() {
				this.$api.getTopBanner({
					type: '1'
				}).then(res => {
					this.topImg = res.data.data
				})
			},
			getdata() {
				const timeOne = new Date()
				const year = timeOne.getFullYear()
				let month = timeOne.getMonth() + 1
				let day = timeOne.getDate()
				month = month < 10 ? '0' + month : month
				day = day < 10 ? '0' + day : day
				const NOW_MONTHS_AGO = `${year}-${month}`
				this.matchInfoDate(NOW_MONTHS_AGO)
			},
			matchInfoDate(e) {
				let data = {
					startTime: e,
					stationId: this.$route.query.id
				}
				this.$api.matchInfoDate(data).then(res => {
					this.infoDate = res.data.data
					this.getDate(this.infoDate[0] || e)
				})
			},
			getDate(e) { //日历
				let data = {
					stationId: this.$route.query.id,
					startTime: e
				}
				this.$api.matchInfoList(data).then(res => {
					res.data.data.forEach(item => {
						item.isJSON = this.isJSON(item.start_time)
						if (this.isJSON(item.start_time)) {
							item.start_time = JSON.parse(item.start_time)
						}
					})
					this.mchList = res.data.data
				})
			},
			isJSON(str) { // 判断是否json
				if (typeof str != 'string') { // 1、传入值必须是 字符串
					return false;
				}
				try {
					var obj = JSON.parse(str); // 2、仅仅通过 JSON.parse(str)，不能完全检验一个字符串是JSON格式的字符串
					if (typeof obj == 'object' && obj) { //3、还必须是 object 类型
						return true;
					} else {
						return false;
					}
				} catch (e) {
					return false;
				}
				return false;
			},
			getTime(dates) { // 时间转换
				let startDate = new Date(dates[0]);
				let endDate = new Date(dates[1]);
				let formattedStartDate =
					`${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;
				let formattedEndDate =
					`${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`;
				if (formattedStartDate == formattedEndDate) {
					return `${formattedStartDate}`;
				} else {
					return `${formattedStartDate} 至 ${formattedEndDate}`;
				}
			},
			plusMonth(e) {
				this.matchInfoDate(e)
			},
			minusMonth(e) {
				this.matchInfoDate(e)
			},
			getIndexColumn() {
				this.$api.getIndexColumn(this.$route.query.id).then(res => {
					this.allData = res.data.data

					this.allData.forEach(item => {
						item.forEach(err => {
							err.articles.forEach(rss => {
								rss.title = rss.title.replace(/<br>/g, ' ')
							})
						})
					})
				})
			},
			jump(id) {
				let routeData = this.$router.resolve({
					path: '/iceskating/details',
					query: {
						subjectid: id,
						navindex: this.$route.query.navindex,
						id: this.$route.query.id,
						refresh: new Date().getTime()
					}
				});
				window.open(routeData.href, '_blank');
			},
			getmore(item, index) {
				this.$router.push({
					path: '/iceskating/layout',
					query: {
						id: this.$route.query.id,
						navpid: item.parentId,
						navid: item.id,
						navindex: index,
						title: item.name,
						refresh: new Date().getTime()
					}
				})
			},
			getmore2(navpid, navid, title, navindex) {
				this.$router.push({
					path: '/iceskating/layout',
					query: {
						id: this.$route.query.id,
						navpid: navpid,
						navid: navid,
						navindex: navindex,
						title: title,
						refresh: new Date().getTime()
					},
				})
			},
			getText(str) {
				const regExp = /<[^>]+>/g; // 匹配所有HTML标签
				const plainText = str.replace(regExp, ''); // 去除所有标签
				return plainText
			},
			getText2(str) {
				const regExp = /<[^>]+>/g; // 匹配所有HTML标签
				const plainText = str.replace(regExp, ''); // 去除所有标签
				return plainText
			},
			Jump(e) {
				if (e.pathUrl == null || e.pathUrl == '') return
				window.open(e.pathUrl, '_blank');
			}
		}
	}
</script>

<style scoped lang="scss">
	img {
		max-width: none;
	}

	.bady {
		// width: 1370px;
		margin: 0 auto;
	}

	.head {
		height: 181px;
		width: 1370px;
		display: flex;
		margin: 0 auto;

		.logo {
			width: 191px;
			height: 114px;
			margin-top: 38px;
		}

		.navigation {
			display: flex;
			align-items: end;
			margin-left: 91px;
			margin-bottom: 44px;
			flex-shrink: 0;

			.piece {
				.name {
					font-size: 19px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					margin-right: 42px;
					cursor: pointer;
					flex-shrink: 0;
				}

				.active {
					background: #0091FF;
					border-radius: 23px;
					color: #fff;
					padding: 7px 20px;
					position: relative;
					top: 7px;
					margin-right: 40px !important;
				}
			}
		}

		.searchfor-box {
			display: flex;
			align-items: end;
			margin-bottom: 44px;
			margin-left: 41px;

			.searchfor {
				width: 25px;
				height: 26px;
				cursor: pointer;
			}

			.qeh {
				font-size: 23px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #0091FF;
				margin-left: 22px;
				cursor: pointer;
				position: relative;
				top: 3px;
			}
		}
	}

	.carousel {
		height: 5.47rem;
		width: 100%;

		.imgUrl {
			width: 100%;
			max-width: 100%;
			height: 5.47rem;
			object-fit: cover;
		}

		::v-deep .el-carousel__arrow {
			height: 0.61rem;
			width: 0.61rem;
			margin-top: 2.3rem;
		}

		::v-deep .el-icon-arrow-left {
			font-size: 0.3rem;
		}

		::v-deep .el-icon-arrow-right {
			font-size: 0.3rem;
		}

		::v-deep .el-carousel__arrow--left {
			margin-left: 14.94rem;
			left: 0 !important;
		}

		::v-deep .el-carousel__arrow--right {
			margin-left: 15.72rem;
			right: inherit;
		}
	}

	.box {
		.title-box {
			width: 1370px;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			padding-top: 40px;
			cursor: pointer;

			.title {
				width: 387px;
				height: 65px;
				font-size: 52px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #0091FF;
				background: url('../../assets/eventtheme/title3.png');
				background-repeat: no-repeat;
				background-size: 100% 100%;
				text-align: center;
			}
		}

		.content-box {

			.content {
				.content-title {
					font-size: 42px;
					font-family: Microsoft YaHei;
					font-weight: 600;
					color: #000000;
					text-align: center;
					cursor: pointer;
					width: 1300px;
					margin: 71px auto 0;
					overflow: hidden;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
					text-overflow: ellipsis;
				}
				
				.content-title:hover{
					color: #0091FF;
				}

				.content-title-box {
					display: flex;
					justify-content: center;
					margin-top: 54px;

					.content-title-box-title {
						font-size: 23px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #000000;
						display: flex;
						align-items: center;
						width: 550px;
						height: 33px;
						overflow: hidden;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
						text-overflow: ellipsis;
						cursor: pointer;

						.sp {
							font-size: 15px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #FFFFFF;
							background: #6BBFFF;
							border-radius: 9px;
							padding: 5px 10px;
							margin-right: 13px;
						}

						.xf {
							position: relative;
							top: 2px;
						}

						.xf:hover {
							color: #0091FF;
						}
					}

					.magin {
						margin-right: 114px;
					}
				}
			}

			.img-layout {
				margin-top: 67px;
				display: flex;
				justify-content: center;

				.layout1 {
					width: 772px;
					cursor: pointer;

					.img {
						width: 772px;
						height: 534px;
					}

					.title {
						font-size: 33px;
						font-family: Microsoft YaHei;
						font-weight: 600;
						color: #000000;
						margin-top: 44px;
						overflow: hidden;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						/* 设置最大显示行数 */
						-webkit-box-orient: vertical;
						text-overflow: ellipsis;
					}
					
					.title:hover{
						color: #0091FF;
					}

					.introduction {
						font-size: 21px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #000000;
						margin-top: 36px;
						line-height: 33px;
						text-align: justify;
						
						overflow: hidden;
						display: -webkit-box;
						-webkit-line-clamp: 3;
						/* 设置最大显示行数 */
						-webkit-box-orient: vertical;
						text-overflow: ellipsis;
					}

					.time {
						font-size: 21px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						margin-top: 29px;
						color: #878787;
					}

					.button {
						width: 298px;
						height: 56px;
						background: #FFFFFF;
						border: 1px solid #000000;
						border-radius: 11px;
						font-size: 19px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						display: flex;
						justify-content: center;
						align-items: center;
						margin: 73px 0 0 0;
						cursor: pointer;

						img {
							width: 30px;
							height: 13px;
							margin-left: 15px;
						}
					}
				}

				.layout2 {
					margin-left: 56px;

					.layout22 {
						width: 465px;
						cursor: pointer;

						.img {
							width: 465px;
							height: 283px;
						}

						.title {
							font-size: 33px;
							font-family: Microsoft YaHei;
							font-weight: 600;
							color: #000000;
							margin-top: 32px;
							overflow: hidden;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							/* 设置最大显示行数 */
							-webkit-box-orient: vertical;
							text-overflow: ellipsis;
						}
						
						.title:hover{
							color: #0091FF;
						}

						.time {
							font-size: 21px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							margin-top: 32px;
							color: #878787;
						}

						.time1 {
							margin-bottom: 81px;
						}
					}
				}
			}

			.button {
				width: 375px;
				height: 70px;
				background: #FFFFFF;
				border: 1px solid #000000;
				font-size: 19px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 73px auto;
				cursor: pointer;

				img {
					width: 30px;
					height: 13px;
					margin-left: 15px;
				}
			}
		}
	}

	.schedule {
		min-height: 922px;
		background: url('../../assets/eventtheme/hbback.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;

		.title-box {
			padding-top: 135px;
		}

		.calendar {
			display: flex;
			justify-content: center;
			margin-top: 64px;

			::v-deep .calendar-card {
				position: relative;
			}
		}

		.flrex {
			width: 691px;
			height: 495px;
			overflow-y: auto;
			margin-left: 30px;

			.information {
				margin-bottom: 63px;
				margin-right: 30px;
			}

			.rubricbox {
				font-size: 30px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #000509;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
			}

			.date {
				.sj {
					font-size: 27px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					text-align: center;
					margin-top: 35px;
					background: #dbefff;
					border-radius: 20px;
					width: 120px;
					margin: 35px auto 0;
				}

				.jtsj {
					font-size: 27px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #888888;
					text-align: center;
					margin-top: 26px;
				}
			}

			.location {
				.dz {
					font-size: 27px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					text-align: center;
					margin-top: 35px;
					background: #dbefff;
					border-radius: 20px;
					width: 120px;
					margin: 35px auto 0;
				}

				.roundtext {
					font-size: 27px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #888888;
					text-align: center;
					margin-top: 25px;
				}
			}
		}

		.flrex::-webkit-scrollbar {
			width: 22px;
			position: absolute;
		}

		.flrex::-webkit-scrollbar-thumb {
			border-radius: 8px;
			background: #E1E4E7;
		}

		.flrex::-webkit-scrollbar-track {
			border-radius: 8px;
			background: #F1F6FA;

		}
	}

	.giveserviceto {
		min-height: 740px;
		background: url('../../assets/eventtheme/hbback1.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;

		.content-box {
			display: flex;
			justify-content: center;
			width: 1300px;
			margin: 98px auto 0;

			.content {
				width: 758px;
				height: 448px;
				background: url('../../assets/eventtheme/hbback8.png');
				background-repeat: no-repeat;
				background-size: 100% 100%;

				.name {
					font-size: 32px;
					font-family: Source Han Sans CN;
					font-weight: bold;
					color: #FFFFFF;
					margin-top: 208px;
					margin-left: 188px;
				}

				.brief {
					width: 495px;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					margin-top: 29px;
					margin-left: 49px;
					overflow: hidden;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 4;
					text-overflow: ellipsis;
					line-height: 29px;
				}
			}

			.content2 {
				width: 518px;
				height: 387px;
				background: url('../../assets/eventtheme/hbback9.png');
				background-repeat: no-repeat;
				background-size: 100% 100%;
				margin-left: 22px;
				margin-top: 61px;

				.name {
					font-size: 32px;
					font-family: Source Han Sans CN;
					font-weight: bold;
					color: #FFFFFF;
					margin-top: 285px;
					margin-left: 173px;
				}

				.brief {
					width: 495px;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #FFFFFF;
					margin-top: 29px;
					margin-left: 49px;
					overflow: hidden;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 4;
					text-overflow: ellipsis;
				}
			}
		}
	}

	.ticketing {
		width: 1179px;
		height: 550px;
		background: url('../../assets/eventtheme/ticket.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
		margin: 115px auto 0;

		.ticketing-box {
			display: flex;
			padding-top: 71px;
			margin-left: 85px;

			.title {
				font-size: 33px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #57B7FF;
			}

			.time-box {
				display: flex;
				align-items: flex-start;
				margin-left: 123px;
			}

			.nian {
				font-size: 13px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #0091FF;
				line-height: 122px;
				transform: rotate(90deg);
				margin-top: -34px;
			}

			.nian1 {
				font-size: 38px;
				font-family: Barlow Condensed SemiBold;
				font-weight: 400;
				color: #0091FF;
			}

			.nian2 {
				font-size: 31px;
				font-family: Abel;
				font-weight: 400;
				color: #0091FF;
				margin-left: 9px;
				margin-top: 7px;
			}

			.nian3 {
				width: 0;
				height: 0;
				border-top: 12px solid transparent;
				border-left: 24px solid #0091FF;
				border-bottom: 12px solid transparent;
				margin-left: 17px;
				margin-top: 16px;
			}

			.nian4 {
				font-size: 38px;
				font-family: Barlow Condensed SemiBold;
				font-weight: 400;
				color: #0091FF;
				margin-left: 14px;
			}

			.nian5 {
				font-size: 31px;
				font-family: Abel;
				font-weight: 400;
				color: #0091FF;
				margin-left: 11px;
				margin-top: 6px;
			}
		}

		.rubric {
			font-size: 58px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #57B7FF;
			margin-left: 86px;
			margin-top: -20px;
		}

		.button {
			width: 310px;
			height: 77px;
			background: #FFFFFF;
			border-radius: 9px;
			font-size: 29px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #00BDFF;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 81px;
			margin-left: 91px;
			cursor: pointer;
		}

		.bottom {
			display: flex;
			align-items: flex-end;

			.time {
				font-size: 38px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				margin-left: 102px;
			}

			.title {
				font-size: 23px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				margin-left: 27px;
				position: relative;
				top: -6px;
			}

			.djs {
				font-size: 25px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				margin-left: 137px;
			}

			.num {
				font-size: 79px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				margin-left: 16px;
				position: relative;
				top: 19px;
			}

			.tian {
				font-size: 25px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				margin-left: 16px;
			}
		}
	}

	.wrc {
		// min-height: 1218px;
		background: url('../../assets/eventtheme/hbback2.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;

		.image-box {
			display: flex;
			flex-wrap: wrap;
			width: 1200px;
			margin: 118px auto 0;

			.img {
				width: 380px;
				height: 260px;
				margin-right: 30px;
				margin-bottom: 30px;
				position: relative;
				overflow: hidden;
				cursor: pointer;

				img {
					width: 100%;
					height: 100%;
				}
				
				img:hover {
					transform: scale(1.15);
				}
			}
			

			.img:nth-child(3n) {
				margin-right: 0;
			}
		}

		.button {
			width: 375px;
			height: 70px;
			background: #F3FAFF;
			border: 1px solid #000000;
			font-size: 19px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 auto;
			margin-top: 55px;
			cursor: pointer;

			img {
				width: 30px;
				height: 13px;
				margin-left: 25px;
			}
		}
	}

	.map {
		height: 652px;
		background: url('../../assets/eventtheme/map.png');
		background-size: cover;



		.pop-up {
			width: 791px;
			height: 568px;
			background: #FFFFFF;
			border-radius: 66px;
			position: absolute;
			right: 225px;
			margin-top: 48px;
		}

		.jianj {
			display: flex;
			cursor: pointer;

			img {
				margin-top: 32px;
				margin-left: 32px;
				width: 342px;
				height: 246px;
				background: #F4F4F4;
				border-radius: 31px;
				min-width: 342px;
				max-width: 342px;
			}

			.title-boxx {
				margin: 0 23px 0 33px;

				.name {
					width: 69px;
					height: 31px;
					font-size: 33px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					margin-top: 43px;
				}

				.name2 {
					width: 333px;
					height: 160px;
					font-size: 17px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					line-height: 33px;
					overflow: hidden;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 5;
					text-overflow: ellipsis;
					margin-top: 37px;
					text-align: justify;
				}
			}
		}

		.bottom {
			display: flex;

			.xq-box {
				margin-top: 70px;
				margin-left: 36px;

				.xq {
					display: flex;
					align-items: center;
					cursor: pointer;

					.xian {
						width: 11px;
						height: 28.9px;
						background: #BFE3FF;
						border-radius: 5.5px;
						flex-shrink: 0;
					}

					.name {
						font-size: 25px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #000000;
						margin-left: 30px;
						overflow: hidden;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
						text-overflow: ellipsis;
					}
				}

				.xq2 {
					margin-top: 30px;
				}
			}
		}

		.button {
			width: 298px;
			height: 56px;
			background: #F4F4F4;
			border: 1px solid #000000;
			border-radius: 11px;
			font-size: 19px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: 29px;
			margin-top: 39px;
			cursor: pointer;
			position: absolute;
			right: 46px;

			img {
				width: 30px;
				height: 13px;
				margin-left: 25px;
			}
		}
	}

	.sponsor-box {
		min-height: 922px;
		background: url('../../assets/eventtheme/hbback3.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;

		.button-box {
			width: 1200px;
			margin: 0 auto;
			display: flex;
			justify-content: center;
		}

		.button {
			width: 375px;
			height: 70px;
			background: #FFFFFF;
			border: 1px solid #000000;
			font-size: 19px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;

			img {
				width: 30px;
				height: 13px;
				margin-left: 15px;
			}
		}
	}

	.sponsor {
		.title {
			font-size: 38px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #000000;
			width: 1200px;
			margin: 0 auto;
			text-align: center;
			margin-top: 58px;
		}

		.xian {
			width: 83px;
			height: 8px;
			background: #0091FF;
			border-radius: 4px;
			margin: 0 auto;
			text-align: center;
			margin-top: 21px;
		}

		.box {
			margin-top: 44px;
			display: flex;
			width: 1400px;
			flex-wrap: wrap;
			margin: 44px auto 0;
			justify-content: center;

			.pp {
				width: 301px;
				height: 149px;
				background: #FFFFFF;
				border: 1px solid #C5F3FF;
				margin-right: 31px;
				margin-bottom: 45px;
				cursor: pointer;

				img {
					width: 100%;
					max-width: 100%;
					height: 100%;
					max-height: 100%;
					object-fit: cover;
				}
			}

			.pp:nth-child(4n) {
				margin-right: 0;
			}

			.pp2 {
				width: 430px;
				height: 126px;
				font-size: 29px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				display: flex;
				align-items: center;
				justify-content: center;
				background: url('../../assets/eventtheme/button.png');
				background-repeat: no-repeat;
				background-size: 100% 100%;
				cursor: pointer;
			}

		}
	}

	.ie-browser .bottom .xq-box .xq .name {
		width: 700px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.ie-browser .content-box .content .content-title-box .content-title-box-title {
		width: 500px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		cursor: pointer;
	}
	.back{
		padding: 15px;
	}
	.back:hover{
		background: #f0f9ff;
	}
</style>